<template>
  <div class="modal-layout">
    <div class="modal-layout__wrapper">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.modal-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 14px;

  &__wrapper {
    max-width: 800px;
    width: 100%;
  }
}
</style>